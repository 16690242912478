export function getDateKey(date) {
  const yyyy = date.getFullYear();
  const mm = `${date.getMonth() + 1}`.padStart(2, '0');
  const dd = `${date.getDate()}`.padStart(2, '0');

  return `${yyyy}-${mm}-${dd}`;
}

export function getFormattedDate(date) {
  return Intl.DateTimeFormat([], { dateStyle: 'long' }).format(date.getTime());
}

<template>
  <section><slot /></section>
</template>

<script>
export default {
  name: 'AnchorBottom',
};
</script>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
  padding-bottom: 0.5em;
}
</style>

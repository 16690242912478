<template lang="">
  <main>
    <section>
      <h2>hello there!</h2>
      <p>Hey, <mark>{{ config.user.name }}</mark>! Are you ready to become a Buff One?</p>
    </section>
    <section>
      <p>
        You have <mark>{{ config.barbells.length }}</mark> barbells and <mark>{{ config.plates.reduce((acc, [q]) => acc + q, 0) }}</mark> plates.
        With them, you can train with up to <mark>{{maxWeight}}kg</mark>.
      </p>
    </section>
    <section class="joke">
      <q>{{ joke }}</q>
    </section>
    <AnchorBottom>
      <button type="submit" @click="$router.push('/calculator')">SHUT UP, AND LET ME LIFT!</button>
      <Spacer height="3" />
      <div>
        Icons made by
        <a href="https://www.flaticon.com/authors/icongeek26" title="Icongeek26">Icongeek26</a>
        from
        <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
      </div>
      <Spacer />
    </AnchorBottom>
    <BackgroundImage src="/img/dumbbell.png" />
  </main>
</template>

<script>
import {
  ref, inject, onMounted, onBeforeUnmount, computed,
} from 'vue';
import AnchorBottom from '../ui/AnchorBottom.vue';
import BackgroundImage from '../ui/BackgroundImage.vue';
import Spacer from '../ui/Spacer.vue';

import { getMessage, JOKES_MESSAGE } from '../../helpers/messages';

export default {
  name: 'MainPage',
  components: {
    AnchorBottom,
    BackgroundImage,
    Spacer,
  },

  setup() {
    const joke = ref('');
    const jokeTimeout = ref(null);
    const config = inject('config');

    onMounted(() => {
      const updateJoke = () => {
        joke.value = getMessage(JOKES_MESSAGE);
      };

      jokeTimeout.value = setInterval(updateJoke, 15000);

      updateJoke();
    });

    onBeforeUnmount(() => {
      clearTimeout(jokeTimeout.value);
    });

    const maxWeight = computed(() => Math.max.apply(null, config.barbells) +
        config.plates.reduce((acc, [q, w]) => acc + (q * w), 0));

    return {
      config,
      joke,
      maxWeight,
    };
  },
};
</script>

<style lang="scss" scoped>
section.joke {
  text-align: center;
  margin: 2em 0;
  font-size: 1.25em;
  color: var(--link-color);
}

div {
  font-size: 75%;
  text-align: center;
}
</style>

<template>
  <div
    className="plate"
    :style="{
      backgroundColor: `hsl(${backgroundHue} 75% 35%)`,
      color: 'white',
      width: `${4 + weight * 0.2}em`,
      height: `${4 + weight * 0.2}em`,
    }"
  >
    <div
      className="plate__weight plate__weight--top"
      :style="`fontSize: ${1 + weight * 0.05}em`"
    >
      {{ display.integer }}{{ display.decimal }}<small>kg</small>
    </div>
    <div
      className="plate__weight plate__weight--bottom"
      :style="`fontSize: ${1 + weight * 0.05}em`"
    >
      {{ display.integer }}{{ display.decimal }}<small>kg</small>
    </div>
  </div>
</template>

<script>
import { getWeight } from '../../helpers/barbellCount';

const COLORS = [
  [0.5, 320],
  [1, 290],
  [1.25, 260],
  [1.5, 200],
  [2, 180],
  [2.5, 150],
  [5, 100],
  [7.5, 75],
  [10, 60],
  [15, 40],
  [20, 20],
  [50, 0],
];

export default {
  name: 'Plate',
  props: ['weight'],
  computed: {
    display() {
      return getWeight(this.weight);
    },
    backgroundHue() {
      return COLORS.find(([w]) => w >= this.weight)[1];
    },
  },
};
</script>

<style lang="scss" scoped>
div.plate {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  border-radius: 100%;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.75);
  margin: 2px;

  .theme-8bit-grayscale & {
    filter: var(--image-filter);
  }

  @media screen and (max-width: 374px) {
    & {
      font-size: 0.85em;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 1em;
    height: 1em;
    background-color: black;
    border-radius: 100%;
    box-shadow: inset 0 0 5px 0px rgba(255, 255, 255, 0.5);
  }
}

div.plate__weight {
  position: absolute;
  height: 100%;

  & > small {
    font-size: 50%;
  }

  &--top {
    align-self: flex-start;
    transform: none;
  }

  &--bottom {
    align-self: flex-end;
    transform: rotate(180deg);
  }
}
</style>

export const JOKES_MESSAGE = 'jokes';

const messages = {
  [JOKES_MESSAGE]: [
    'Why did the fish stop lifting weights? He pulled a mussel.',
    'You think you can only have chicken and broccoli to gain muscles? No, whey!',
    'Why does the burger hit the gym? It wants better buns.',
    'What do hairdressers do at the gym? Curls.',
    'When it comes to workout, I always prefer cardio. It helps me in the long run.',
    'I spent 6 months lifting, and got no gain. I guess it\'s not working out.',
    'What do you call a T-Rex who has been lifting all day? Dino-sore.',
    'Why doesn\'t Waldo go to the gym? Because no one can spot him.',
    'What\'s Cardi B super-fit sister\'s name? Cardi O.',
    'Why did the Uber driver cancel his gym membership? He didn\'t Lyft.',
    'I\'m gonna tell you a great joke... weight for it...',
    'What\'s a pig\'s strongest muscle? The hamstring.',
    'I don\'t hate leg days. It\'s the two days after I can\'t stand.',
    'How do bodybuilders defeat Covid? Whey Isolate.',
    'What do zombie bodybuilders want more than anything? GaaAAAiiNnns!!!',
  ],
};

export const getMessage = (type) => {
  const list = messages[type] || [];

  return list[Math.floor(Math.random() * list.length)];
};

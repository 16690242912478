import { sum } from './numbers';

const combinationsCache = {};

const samePlates = (p1 = [], p2 = []) => (
  p1.length === p2.length &&
      p1.every(([q1, w1], index) => {
        const [q2, w2] = p2[index] || [];

        return q1 === q2 && w1 === w2;
      })
);

export function barbellCount(target, barbell, plates) {
  // the goal for each side of the barbell
  const sideGoal = (target - barbell) / 2;

  if (!samePlates(plates, combinationsCache.plates)) {
    // the available plates are just half the ones we really have
    const platesList = plates.reduce((acc, [n, w]) => [...acc, ...[...Array(Math.floor(n / 2))].map(() => w)], []);

    let combinations = [[]];

    for (const weight of platesList) {
      const newCombinations = [];

      for (const c of combinations) {
        newCombinations.push([...c, weight]);
      }

      combinations = [...combinations, ...newCombinations];
    }

    combinationsCache.plates = [...plates]; // need a copy here
    combinationsCache.combinations = combinations;
  }

  const { combinations } = combinationsCache;

  let sideDiff = sideGoal;
  let sideSum = 0;
  for (const c of combinations) {
    const newSideSum = sum(c);
    const newSideDiff = Math.abs(newSideSum - sideGoal);
    if (newSideDiff < sideDiff) {
      sideDiff = newSideDiff;
      sideSum = newSideSum;
    }
  }

  const total = barbell + (sideSum * 2);
  const diff = total - target;

  const solutions = combinations.filter((c) => sum(c) === sideSum);
  const minLenSolution = solutions.reduce((acc, s) => (s.length < acc ? s.length : acc), Number.POSITIVE_INFINITY);
  const solution = solutions.find((s) => s.length === minLenSolution);

  return {
    solutions,
    target,
    total,
    solution,
    diff,
  };
}

export function dumbbellCount(target, dumbbell, plates) {
  const dumbbellPlates = plates
    .map(([q, w]) => [Math.floor(q / 2), w])
    .filter(([, w]) => w <= 5);

  return barbellCount(target, dumbbell, dumbbellPlates);
}

export const getWeight = (weight) => {
  const totalInt = Math.floor(weight);
  const totalDec = weight - totalInt;

  return {
    integer: totalInt ? `${totalInt}` : '',
    decimal: (
      totalDec === 0.25 ? '¼' :
      totalDec === 0.5 ? '½' :
      totalDec === 0.75 ? '¾' :
      /* else */ ''
    ),
  };
};

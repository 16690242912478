<template lang="">
  <form @submit="addEntry">
    <section class="exercise">
      <div>exercise</div>
      <select name="exercise" :value="config.diary?.latest?.exercise">
        <option value="Arms Curls">Arms Curls</option>
        <option value="Back Squat">Back Squat</option>
        <option value="Bench Press">Bench Press</option>
        <option value="Lunges">Lunges</option>
        <option value="Military Press">Military Press</option>
        <option value="Pull Up">Pull Up</option>
        <option value="Push Press">Push Press</option>
      </select>
    </section>
    <section class="count">
      <div>
        <span>sets</span>
        <input name="sets" type="number" step="1" :value="config.diary?.latest?.sets || 3" />
      </div>

      <div>
        <span>reps</span>
        <input name="reps" type="number" step="1" :value="config.diary?.latest?.reps || 10" />
      </div>

      <div>
        <span>kg</span>
        <input name="weight" type="number" step="0.25" :value="config.diary?.latest?.weight || 0" />
      </div>
    </section>

    <button type="submit">Add</button>
    <a @click="emit('close-form')">← Back</a>
  </form>
</template>

<script setup>
import { computed, inject } from 'vue';
import { getDateKey } from '../../helpers/diaryHelpers';
import { preventSubmit } from '../../helpers/dom';

const props = defineProps({
  date: Date,
});

const emit = defineEmits(['close-form']);

const config = inject('config');

const dateKey = computed(() => getDateKey(props.date));

function addEntry(e) {
  preventSubmit(e);

  const sets = +e.target.elements.sets.value;
  const reps = +e.target.elements.reps.value;
  const weight = +e.target.elements.weight.value;
  const exercise = e.target.elements.exercise.value;

  if (!sets || !reps || !exercise) return;

  const currentDiary = config.diary || {};

  config.diary = {
    ...currentDiary,
    latest: {
      exercise,
      sets,
      reps,
      weight,
    },
    [dateKey.value]: [
      ...(currentDiary[dateKey.value] || []),
      {
        id: Math.random(),
        date: props.date,
        exercise,
        sets,
        reps,
        weight,
      },
    ],
  };

  emit('close-form');
}
</script>

<style lang="scss" scoped>
.exercise {
  text-align: center;
  margin-bottom: 1rem;

  & > * {
    margin: 0.25em 0;
  }
}

.count {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
  }
}
</style>

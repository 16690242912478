<template>
  <nav>
    <div
      className="indicator"
      :style="indicatorStyle"
    />

    <router-link v-for="([target, icon, text]) in menus" :key="target" :to="target">
      <Icon
        :className="`${target === $route.path ? 'selected' : ''}`"
        :src="`/img/menu_${icon}${target === $route.path ? '_selected' : ''}.png`"
        alt=""
        size="l"
      />
      {{ text }}
    </router-link>
  </nav>
</template>

<script>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import Icon from './Icon.vue';

export default {
  name: 'Menu',
  components: {
    Icon,
  },
  props: {
    user: {
      default: { type: 'user0', name: 'user' },
    },
  },
  setup(props) {
    const menus = computed(() => [
      ['/calculator', 'calculator', 'Calculator'],
      ['/diary', 'diary', 'Diary'],
      ['/', 'home', 'Home'],
      ['/profile', `profile_${props.user.type}`, props.user.name],
    ]);

    const indicatorStyle = computed(() => {
      const route = useRoute();
      const { path } = route;

      const selectedIndex = menus.value.findIndex(([target]) => target === path);
      const indicatorWidth = 100 / menus.value.length;
      const indicatorLeft = selectedIndex * indicatorWidth;

      return {
        left: `${indicatorLeft}%`,
        width: `${indicatorWidth}%`,
      };
    });

    return {
      menus,
      indicatorStyle,
    };
  },
};
</script>

<style scoped lang="scss">
@media screen and (min-width: 1024px) {
  a {
    min-height: 8em;
    justify-content: center;
    margin-bottom: 2em;
    zoom: 1.5;
  }

  a.router-link-active {
    background-color: var(--root-primary-background-color);
    border-radius: 2em;;
  }

  .indicator {
    display: none;
  }
}

nav {
  width: 100%;
  display: flex;
  position: relative;

  .indicator {
    content: '';
    height: 2px;
    background: var(--root-primary-background-color);
    position: absolute;
    top: 0;
    left: 0;
    transition-property: left;
    transition-duration: 0.5s;
  }

  a {
    &::before {
      content: none;
    }

    color: var(--root-color);
    display: inline-flex;
    padding: 0.5em 0;
    font-size: 0.75em;
    flex: 1 0 20%;
    flex-direction: column;
    align-items: center;

    &:focus {
      filter: none;
    }
  }

  img {
    html:not(.theme-8bit-grayscale) & {
      filter: Invert();
    }
    margin-bottom: 0.125em;
  }
}
</style>

<template>
<button class="remove"><span role="img">❌</span></button>
</template>

<style lang="scss" scoped>
button {
  display: inline-block;
  background-color: transparent;
  width: auto;
  border-radius: 0.5em;
  margin-left: 1em;

  &:focus {
    box-shadow: none;
    background-color: #222;
  }
}
</style>

import { createApp } from 'vue/dist/vue.esm-bundler';
import { createRouter, createWebHashHistory } from 'vue-router';
import App from './App.vue';

import MainPage from './components/pages/MainPage.vue';
import CalculatorPage from './components/pages/CalculatorPage.vue';
import DiaryPage from './components/pages/DiaryPage.vue';
import ProfilePage from './components/pages/ProfilePage.vue';
import CreditsPage from './components/pages/CreditsPage.vue';
import './registerServiceWorker';

const routes = [
  { path: '/', component: MainPage },
  { path: '/calculator', component: CalculatorPage },
  { path: '/diary', component: DiaryPage },
  { path: '/profile', component: ProfilePage },
  { path: '/credits', component: CreditsPage },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

createApp(App).use(router).mount('body');

<template>
  <article>
    <header>
      <a v-if="!formOpen" @click="changeDate(-1)" class="arrow">←</a>
      <div>
        <h1>{{ getFormattedDate(props.date) }}</h1>
      </div>
      <a v-if="!formOpen" @click="changeDate(+1)" class="arrow">→</a>
      <a class="go-to-today" v-if="!isToday()" @click="today()">TODAY</a>
    </header>
    <main>
      <template v-if="formOpen">
        <DiaryForm :date="props.date" @close-form="formOpen = false" />
      </template>
      <template v-else>
        <button @click="setFormOpen(true)">Add Exercise</button>
        <Display v-if="totalDayWeight.integer > 0 || totalDayWeight.decimal" label="kg" title="today">
          <template v-slot:data>
            <small>{{totalDayWeight.integer}}</small>{{totalDayWeight.decimal}}
          </template>
        </Display>
        <section v-for="(entries, exercise) of exercises" :key="exercise">
          <h3>{{ exercise }}</h3>
          <table>
            <tr v-for="entry of entries" :key="entry.id">
              <td>{{ entry.sets }} sets</td>
              <td>{{ entry.reps }} reps</td>
              <td>{{ entry.weight }} kg</td>
              <td><RemoveButton @click="remove(entry.id)" /></td>
            </tr>
          </table>
        </section>
      </template>
    </main>
  </article>

</template>

<script setup>
import { computed, inject, ref } from 'vue';
import DiaryForm from './DiaryForm.vue';
import RemoveButton from './RemoveButton.vue';
import Display from './Display.vue';
import { getWeight } from '../../helpers/barbellCount';
import { getDateKey, getFormattedDate } from '../../helpers/diaryHelpers';

const props = defineProps({
  date: Date,
});

const emit = defineEmits(['change-date']);

const dateKey = computed(() => getDateKey(props.date));

const config = inject('config');

const dayDiary = computed(() => {
  const entries = [...config?.diary?.[dateKey.value] || []];

  return entries.sort((a, b) => (
    a.exercise === b.exercise ? a.weight - b.weight :
    a.exercise < b.exercise ? -1 : 1
  ));
});

const exercises = computed(() => dayDiary.value.reduce((acc, entry) => {
  acc[entry.exercise] = acc[entry.exercise] || [];
  acc[entry.exercise].push(entry);

  return acc;
}, {}));

function isToday() {
  return getDateKey(new Date(Date.now())) === dateKey.value;
}

function today() {
  emit('change-date', new Date(Date.now()));
}

function changeDate(delta) {
  emit('change-date', new Date(props.date.getTime() + (delta * 24 * 60 * 60 * 1000)));
}

const formOpen = ref(false);
function setFormOpen(open) {
  formOpen.value = open;
}

function remove(id) {
  const currentDiary = config.diary || {};
  config.diary = {
    ...currentDiary,
    [dateKey.value]: (currentDiary[dateKey.value] || []).filter((e) => e.id !== id),
  };
}

const totalDayWeight = computed(() => getWeight(dayDiary.value.reduce((acc, entry) => acc + (entry.sets * entry.reps * entry.weight), 0)));
</script>

<style lang="scss" scoped>
header {
  display: flex;
  margin-bottom: 2rem;
  position: relative;

  div {
    text-align: center;
    flex-grow: 1;
    white-space: nowrap;

    h1:before {
      content: none;
    }
  }

  .go-to-today {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
  }

  a.arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;

    &:before {
      content: none;
    }
  }
}

section {
  h3 {
    font-size: 1.25rem;
    &:before { content: none; }
  }
}

table {
  width: 100%;
}

td {
  padding: 0.5rem 0;
  text-align: center;

  button {
    margin: 0;
  }
}
</style>

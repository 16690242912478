<template lang="">
  <main>
    <h2>credits</h2>
    <section>
      <p>
        The configuration is saved in the <mark>local storage</mark> of the device.
      </p>
      <p>
        Coded by
        <a href="https://davide.cassenti.it" title="Davide Cassenti">Davide Cassenti</a>, using
        <a href="https://vuejs.org/" title="Vue.js">Vue.js</a>, and a bunch of others
      </p>
      <Spacer />
      <div>
        Icons made by
        <a href="https://www.flaticon.com/authors/icongeek26" title="Icongeek26">Icongeek26</a>
        from
        <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
      </div>
    </section>
    <BackgroundImage src="/img/design.png" />
  </main>
</template>

<script>
import Spacer from '../ui/Spacer.vue';
import BackgroundImage from '../ui/BackgroundImage.vue';

export default {
  name: 'CreditsPage',
  components: {
    BackgroundImage,
    Spacer,
  },
};
</script>

<style lang="scss"></style>

<template>
  <img :style="style" />
</template>

<script>
const SIZES = {
  xs: 1,
  s: 1.5,
  m: 2,
  l: 3,
  xl: 4,
  xxl: 8,
};
export default {
  name: 'Icon',
  props: ['inverted', 'size'],
  computed: {
    style() {
      const size = `${SIZES[this.size || 'm']}em`;

      return {
        width: size,
        height: size,
        filter: this.inverted ? 'invert(1)' : '',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
img {
  vertical-align: middle;
}
</style>

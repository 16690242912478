<template>
  <header>
    <h1>
      <Icon src="/img/muscles.png" alt="" size="s" style="margin-right: 0.25em" />
      <router-link to="/"><small>I am the </small>Buff One</router-link>
    </h1>
    <Icon
      className="credits"
      :src="`/img/faq${$route.path === '/credits' ? '_selected' : ''}.png`"
      alt="credits"
      inverted="true"
      size="s"
      @click="$router.push('/credits')"
    />
  </header>
  <router-view />
  <footer><Menu :user="config.user" /></footer>
</template>

<script>
import { inject } from 'vue';
import Menu from './Menu.vue';
import Icon from './Icon.vue';

export default {
  name: 'Page',
  components: {
    Icon,
    Menu,
  },
  setup() {
    const config = inject('config');

    return {
      config,
    };
  },
};
</script>

<style scoped lang="scss">
header {
  grid-area: header;
  padding: 0.5em 0.5em 0.5em 0;
  display: flex;

  @media screen and (max-width: 374px) {
    & {
      font-size: 0.85em;
    }
  }
}

h1 {
  margin: 0;
  flex-grow: 1;

  &::before {
    content: none;
  }

  small {
    font-size: 50%;
  }
}

footer {
  background-color: transparent;
  border: 0;
  grid-area: footer;
  font-size: 0.75em;
  text-align: center;
  padding: 0;
  box-shadow: 0 0 2px -1px var(--root-color);

  @media screen and (max-width: 374px) {
    & {
      font-size: 0.625em;
    }
  }
}
</style>

export const digits = (d) => (num) => `${num}`.padStart(d, '0');
export const digits2 = digits(2);

export const sum = (numbers) => numbers.reduce((acc, n) => acc + n, 0);

export const number2word = (n) => {
  if (n > 99) return 'a lot of';

  const numbers2word = [
    'zero',
    'one',
    'two',
    'three',
    'four',
    'five',
    'six',
    'seven',
    'eight',
    'nine',
    'ten',
    'eleven',
    'twelve',
    'thirteen',
    'forteen',
    'fifteen',
    'sixteen',
    'seventeen',
    'eighteen',
    'nineteen',
  ];

  const decs = [
    'ten',
    'twenty',
    'thirty',
    'forty',
    'fifty',
    'sixty',
    'seventy',
    'eithgy',
    'ninety',
  ];

  if (n < 20) return numbers2word[n];
  if (!(n % 10)) return decs[(n / 10) - 1];

  return `${decs[Math.floor(n / 10) - 1]} ${numbers2word[n % 10]}`;
};

<template lang="">
  <main>
    <section>
      <form data-type="user" onSubmit={preventSubmit}>
        <h2>user</h2>
        <RadioIcons
          name="user.type"
          :value="config.user.type"
          :values="['user0', 'user1', 'user2']"
          @change="change"
        >
          <template v-slot="{ value: userType }">
            <Icon size="xl" :src="`/img/${userType}.png`" :alt="userType" :value="userType" v-model="config.user.type" />
          </template>
        </RadioIcons>
        <label>
          <input
            autocomplete="off"
            name="name"
            placeholder="name"
            type="text"
            v-model.lazy="config.user.name"
          />
        </label>
      </form>
    </section>

    <section>
      <form data-type="app" @submit={preventSubmit}>
        <h2>app</h2>

        <RadioIcons
          name="theme"
          :value="config.theme"
          :values="availableThemes"
          @change="change"
        >
          <template v-slot="{ value: color }">
            <div class="theme-color" :value="color" :key="color">
              <span :style="`background-color: var(--theme-8bit-${color})`" />
            </div>
          </template>
        </RadioIcons>
      </form>
    </section>

    <section>
      <h2>barbells</h2>
      <div><Barbell type="barbell" /></div>
      <form @submit="preventSubmit">
        <div v-for="(b, i) in config.barbells" :key="b">
          <input
            type="number"
            step="0.25"
            v-model.lazy.number="config.barbells[i]"
          /> kg
          <RemoveButton data-config="barbells" :data-index="i" className="remove" @click="remove" />
        </div>
        <AddButton data-config="barbells" data-value="20" @click="add" />
      </form>
    </section>

    <section>
      <h2>plates</h2>
      <section>
        <Plate weight="5" /><Plate weight="10" /><Plate weight="20" />
      </section>
      <form @submit="preventSubmit">
        <div v-for="([, w], i) in config.plates" :key="w">
          <input
            type="number"
            step="0.25"
            v-model.lazy.number="config.plates[i][0]"
          /> x <input
            type="number"
            step="0.25"
            v-model.lazy.number="config.plates[i][1]"
          />
          <RemoveButton data-config="plates" :data-index="i" className="remove" @click="remove" />
        </div>
        <AddButton data-config="plates" data-value="[2, 5]" @click="add" />
      </form>
    </section>
  </main>
</template>

<script>
import { inject } from 'vue';
import { preventSubmit } from '../../helpers/dom';

import Icon from '../ui/Icon.vue';
import RadioIcons from '../ui/RadioIcons.vue';
import Plate from '../ui/Plate.vue';
import Barbell from '../ui/Equipment.vue';
import RemoveButton from '../ui/RemoveButton.vue';

const AddButton = {
  template: '<button className="add">+ add more</button>',
};

export default {
  name: 'ProfilePage',
  components: {
    Icon,
    RadioIcons,
    AddButton,
    RemoveButton,
    Plate,
    Barbell,
  },

  setup() {
    const config = inject('config');

    return {
      config,
      availableThemes: [
        'blue',
        'green',
        'pink',
        'red',
        'yellow',
        'grayscale',
      ],

      change(e) {
        const path = e.currentTarget.getAttribute('name').split('.');
        const name = path.pop();

        const obj = path.reduce((acc, n) => acc[n], config);

        obj[name] = e.target.value;
      },

      add(e) {
        const { config: cc, value } = e.currentTarget.dataset;

        const parsedValue = JSON.parse(value);

        config[cc].push(parsedValue);
      },

      remove(e) {
        const { index, config: cc } = e.currentTarget.dataset;

        config[cc].splice(index, 1);
      },

      preventSubmit,
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  main {
    display: grid;
    grid-template-columns: 50% 50%;
  }
}

div.theme-color {
  box-sizing: border-box;
  width: 2em;
  height: 2em;
  display: inline-block;
  margin: 0.25em;
  padding: 0.375em;

  span {
    box-sizing: border-box;
    display: inline-block;
    outline: 2px solid black;
    width: 1.25em;
    height: 1.25em;
    position: relative;
  }

  &[data-checked] span::after {
    content: '✓';
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

button.add {
  background-color: var(--root-primary-background-color);
  color: var(--root-primary-color);
  width: auto;
  border-radius: 0.5em;
}
</style>

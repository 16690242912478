<template>
  <span
    type={type}
    :className="`equipment ${type}`"
    :style="barStyle"
  >
    <span class="disc disc--left" :style="leftDiscStyle" />
    <span class="disc disc--right" :style="rightDiscStyle" />
  </span>
</template>

<script>
export default {
  name: 'Equipment',
  props: ['type'],

  setup(props) {
    const [width, height] = (
      props.type === 'dumbbell' ? [5, 3] :
      props.type === 'barbell' ? [12, 5] :
      [8, 4]
    );

    const barStyle = {
      width: `${width}em`,
      margin: `${(0.5 + (height / 2))}em 0.5em`,
    };

    const discStyle = {
      width: `${height / 3}em`,
      height: `${height}em`,
      top: `-${((height / 2) - 0.5)}em`,
    };

    const leftDiscStyle = { ...discStyle, left: '0.25em' };
    const rightDiscStyle = { ...discStyle, right: '0.25em' };

    return {
      barStyle,
      leftDiscStyle,
      rightDiscStyle,
    };
  },
};
</script>

<style scoped lang="scss">
span {
  display: inline-block;
    height: 1em;
    background-color: lightgray;
    position: relative;
    border-radius: 0.125em;
    box-shadow: inset 0 0 3px 0 rgba(0, 0, 0, 0.75);

    .disc {
      content: '';
      position: absolute;
      background-color: #222;
      border-radius: 0.125em;
      box-shadow: inset 0 0 3px 0 rgba(255, 255, 255, 0.25);
    }

    @media screen and (max-width: 374px) {
      & {
        font-size: 0.85em;
      }
    }
}
</style>

const defaultConfig = {
  user: {
    name: 'user',
    type: 'user0',
  },

  barbells: [
    6,
    6.5,
    7.5,
  ],
  plates: [
    [4, 0.5],
    [12, 1],
    [8, 2],
    [6, 5],
    [2, 10],
    [2, 20],
  ],
};

export const loadConfiguration = () => {
  try {
    return {
      ...defaultConfig,
      ...JSON.parse(localStorage.config),
    };
  } catch (e) {
    return defaultConfig;
  }
};

export const saveConfiguration = (newConfig) => {
  localStorage.config = JSON.stringify(newConfig);
};

<template>
  <div className="plates">
    <div
      :key="`group-${group}`"
      v-for="group in plates"
      :style="`position: relative; display: inline-block; margin-right: ${0.5 + (0.75 * group.length)}em`"
    >
      <Plate
        v-for="(plate, index) in group"
        :key="plate"
        :weight="plate"
        :style="{
          position: index === 0 ? 'relative' : 'absolute',
          left: `${0.75 * index}em`,
          minWidth: '1em',
        }"
      />
    </div>
  </div>
</template>

<script>
import Plate from './Plate.vue';

export default {
  name: 'PlateSolutions',
  props: ['solution'],
  components: {
    Plate,
  },
  computed: {
    plates() {
      return this.solution.reduce((acc, weight, index) => {
        const prevWeight = index > 0 && this.solution[index - 1];

        if (weight !== prevWeight) acc.push([]);

        const container = acc[acc.length - 1];

        acc[acc.length - 1] = [
          ...container,
          weight,
        ];

        return acc;
      }, []);
    },
  },
};
</script>

<style lang="scss" scoped>
div.plates {
  text-align: center;
}
</style>

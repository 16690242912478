<template lang="">
  <main>
    <section>
      <form @submit="preventSubmit">
        <h2>calculator</h2>

        <p>
          I want to lift
          <input name="target" type="number" step="0.5" v-model="config.lastTarget" /> kg
        </p>
        <p>
          ...using one barbell

          <label v-for="(b) in barbells" :key="b">
            <input type="radio" name="barbell" v-model="config.lastBarbell" :value="b" /> of {{b}} kg
          </label>
        </p>

        <Spacer />

        <template v-if="solution.total > 0">
          <Display label="kg" title="you will lift">
            <template v-slot:data>
              <small>{{display.integer}}</small>{{display.decimal}}
            </template>
          </Display>
          <Spacer height="1" />
          <PlatesSolution :solution="solution.solution" />
        </template>
      </form>
    </section>

    <BackgroundImage src="/img/abacus.png" />
  </main>
</template>

<script>
import { computed, inject } from 'vue';
import Display from '../ui/Display.vue';
import Spacer from '../ui/Spacer.vue';
import PlatesSolution from '../ui/PlatesSolution.vue';
import BackgroundImage from '../ui/BackgroundImage.vue';

import { barbellCount, getWeight } from '../../helpers/barbellCount';
import { preventSubmit } from '../../helpers/dom';

export default {
  name: 'MainPage',

  components: {
    Display,
    Spacer,
    PlatesSolution,
    BackgroundImage,
  },

  setup() {
    const config = inject('config');

    const solution = computed(() => {
      const {
        plates, lastTarget, lastBarbell,
      } = config;

      return barbellCount(lastTarget, lastBarbell, plates);
    });

    const { barbells } = config;
    const display = computed(() => getWeight(solution.value.total));

    return {
      config,
      solution,
      barbells,
      display,
      preventSubmit,
    };
  },
};
</script>

<style lang="scss">
label {
  width: fit-content;
}
</style>

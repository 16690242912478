<template>
  <figure>
      <img :src="src" alt="" />
  </figure>
</template>

<script>
export default {
  name: 'BackgroundImage',
  props: {
    src: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
figure {
  position: absolute;
  bottom: 0;
  opacity: 0.175;
  z-index: -1;
  width: 100%;
  text-align: center;
}
</style>

<template lang="">
  <main>
    <section>
      <form @submit="preventSubmit">
        <h2>diary</h2>
        <DiaryEntry
          :date="date"
          @change-date="date = $event"
        />
      </form>
    </section>

    <BackgroundImage src="/img/abacus.png" />
  </main>
</template>

<script setup>
import { ref } from 'vue';
import DiaryEntry from '../ui/DiaryEntry.vue';
import BackgroundImage from '../ui/BackgroundImage.vue';

function fixDate(time) {
  const date = new Date(time);
  date.setHours(12);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
}

const date = ref(fixDate(Date.now()));
</script>

<style lang="scss" scoped>
h3 {
  text-align: center;
  text-transform: uppercase;

  &::before {
    content: none;
  }
}
</style>

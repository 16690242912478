<template>
  <label v-for="vv in values" :key="vv" style="display: inline-block;">
    <div :style="`background-color: ${value=== vv && 'var(--root-primary-background-color)'}`"><slot :value="vv" /></div>
    <input
      type="radio"
      style="display: none;"

      :name="name"
      :checked="vv === value"
      :value="vv"

      @change="onChange"
      @blur="onBlur"
    />
  </label>
</template>

<script>
export default {
  name: 'RadioIcons',
  props: ['name', 'value', 'values', 'onChange', 'onBlur'],
};
</script>

<style lang="scss" scoped>
</style>

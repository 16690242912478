<template>
  <div className="display">
    <div v-if="title" className="text title">{{ title }}</div>
    <div v-for="d in dataArr" :key="d">
      <mark className="data"><slot name="data" /></mark>
    </div>
    <div v-if="label" className="text label">{{ label }}</div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'Icon',
  props: ['title', 'label', 'data'],
  setup(props) {
    const dataArr = computed(() => (Array.isArray(props.data) ? props.data : [props.data]));

    return {
      dataArr,
    };
  },
};
</script>

<style lang="scss" scoped>
div.display {
  text-align: center;
}

mark.data {
  border-radius: 0.125em;
  font-size: 2.5em;
}

div.text {
  text-transform: uppercase;
  font-size: 1.5em;
  padding: 0;
}
</style>

<template>
  <div :style="style"></div>
</template>

<script>
export default {
  name: 'Spacer',
  props: {
    height: {
      type: Number,
      default: 1,
    },
  },
  computed: {
    style() {
      return {
        height: `${this.height}em`,
      };
    },
  },
};
</script>

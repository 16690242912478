<template>
  <Page />
</template>

<script>
import {
  onMounted, ref, provide, watch,
} from 'vue';

import Page from './components/ui/Page.vue';
import { loadConfiguration, saveConfiguration } from './helpers/configuration';

const updateTheme = (newTheme) => {
  const html = document.querySelector('html');
  html.className = `theme-8bit-${newTheme}`;
};

export default {
  name: "I'm the Buff One",
  components: {
    Page,
  },

  setup() {
    const config = ref(loadConfiguration());
    const theme = ref(config.value.theme);

    onMounted(() => updateTheme(theme.value));

    watch(
      config,
      (newConfig) => {
        theme.value = newConfig.theme;
        saveConfiguration(newConfig);
      },
      { deep: true },
    );

    watch(theme, updateTheme);

    provide('config', config.value);

    return {
      config,
      theme: config.value.theme,
    };
  },
};
</script>

<style lang="scss">
@import '@davidecassenti/8.bit-css/src/scss/8.bit.scss';

body {
  background-image: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content 1fr max-content;
  gap: 0px 0px;
  grid-template-areas:
    'header'
    'content'
    'footer';

  main {
    padding: 0 5vw;
    overflow-x: hidden;
  }
}

main {
  grid-area: content;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  width: 100vw;
  margin: 0 auto;
  width: -webkit-fill-available;
}

input[type='number'] {
  width: 4em;
}
</style>
